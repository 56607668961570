<template>
  <div class="contact">
    <v-container fluid grey darken-4 white--text>
      
           <h1 align="left">Kontaktieren Sie uns!</h1>

      <p id="contactText" align="left" class="my-8">Gerne beantworten wir Ihre Fragen <br> Schreiben sie uns dafür einfach per e-Mail, Instagram oder mit unserem beigefügten Kontaktformular</p>

      <v-row>
      <v-btn color="transparent white--text" depressed href="https://www.instagram.com/zep_beer/">
          <v-icon class="mr-4">mdi-instagram</v-icon>
          @zep_beer
      </v-btn> 
      </v-row>   
      <v-row>
      <v-btn color="transparent white--text" depressed href="mailto:kontakt@zep.beer">
          <v-icon class="mr-4">mdi-email-send</v-icon>
          kontakt@zep.beer
      </v-btn>
      </v-row>

    </v-container>

    <contact-form></contact-form>
  </div>
</template>

<script>
import ContactForm from '../components/ContactForm.vue'
export default {
  components: { ContactForm },

}
</script>

<style scoped>
#contactText{
  font-size: 16px;
}
</style>