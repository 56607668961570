<template>
<div>
    <v-container fluid grey darken-4 white--text>
        <v-form id="abc" class="pa-2" ref="contactForm" v-model="form">
            <h1 class="ma-2">Kontaktformular</h1>
            <v-text-field v-model="name" :rules='namerules' color="#fff200" label="Name" dark outlined required></v-text-field>
            <v-text-field v-model="email" :rules='emailrules' color="#fff200" label="E-Mail" dark outlined required></v-text-field>
            <v-text-field v-model="betreff" :rules='betreffrules' color="#fff200" label="Betreff" dark outlined required></v-text-field>
            <v-textarea v-model="nachricht" :rules='nachrichtrules' color="#fff200" label="Nachricht" dark auto-grow outlined required></v-textarea>
            <v-row align="start" class="ml-0 mb-2">
            <v-btn @click='validate()' color="transparent" depressed>
            <v-btn left :disabled='!form' @click='submit()' color="#fff200" outlined dark>Abschicken</v-btn>
            </v-btn>
            </v-row>
        </v-form>
    </v-container>
    </div>
</template>

<script>
import email from 'emailjs-com'
email.init("user_cOmxgRMNUc4wffyX6jubF");

export default {
    data(){return{
        form: true,
        name: '',
        betreff: '',
        email: '',
        message: '',
        namerules:[value => !!value || 'Bitte einen Namen eingeben',
        value => value.length > 1 || 'Bitte mindestens 2 Zeichen eingeben'],
        emailrules:[value=> !!value || 'Bitte eine e-Mail Adresse angeben',
            value => this.useRegex(value) || 'Bitte eine gültige e-Mail Adresse angeben'
        ],
        betreffrules:[value => !!value || 'Bitte einen Betreff eingeben'],
        nachrichtrules:[value => !!value|| 'Bitte eine Nachricht eingeben']
    }
    },
    methods: {
        validate(){
            this.$refs.contactForm.validate();
            setTimeout(()=>{this.$refs.contactForm.resetValidation()}, 2500)
        },

        // Ab hier Fremdcode, generiert auf https://regex-generator.olafneumann.org/
        useRegex(input) {
            let regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            return regex.test(input);
        },

        // Bis hier Fremdcode 

        submit(){
            let params= {
                from_name: this.name,
                subject: this.betreff,
                mail_adress: this.email,
                contact_message: this.message
            }
            email.send('default_service','template_u2wgn13',params);
            this.$refs.contactForm.reset();
        }
    }
}
</script>
<style scoped>
h1{
    text-align: start;
    font-weight: 400;
}
</style>
